<template>
  <img :src="require(`@/assets/icons/${chain || 'bsc'}-icon.svg`)" v-bind="$attrs" />
</template>

<script >
export default {
  // @Prop({ default: 'bsc' }) chain!:
  props: {
    chain: { type: String, default: 'bsc' },
  },
}
</script>

<style scoped></style>
